import React from "react";
import { BetData, GameSkin, HeroPlayer, TableData } from "../types";
import moment from "moment";
import { TFunction } from "i18next";
import { useDispatch } from "react-redux";
import { openModal } from "../store/slices/modalSlice";
import { Button } from "react-bootstrap";
import SkinModal from "../components/Modals/SkinModal";
import { CloseFooter } from "../components/Modals";
import CopyField from "../components/UI/CopyField";

export const formatGamesHistory = (data: BetData): TableData => ({
  player: (
    <div className="d-flex px-2 align-items-center justify-content-start gap-3">
      <img
        src={data.userData.avatar}
        className="rounded-full icon-image"
        alt="Player Image"
      />
      <span className="overflow-ellipsis username">{data.userData.name}</span>
    </div>
  ),
  bet: data.towers.betValue,
  profit: (
    <span className={data.winnings > 0 ? "text-win" : "text-red"}>
      {data.winnings}
    </span>
  ),
  step: data.towers.currentLevel,
  difficulty: data.towers.level,
});

export const formatHeroesHistory = (data: HeroPlayer): TableData => ({
  rank: (
    <div className="d-flex px-2 align-items-center justify-content-start gap-3">
      <img
        src="/images/trophy.png"
        className="rounded-full icon-image"
        alt="Player Image"
      />
      {data.rank}
    </div>
  ),
  player: <div className="overflow-ellipsis username">{data.name}</div>,
  wagered: data.amount,
});

const dateKeys = [
  "time_created",
  "timestamp",
  "time_updated",
  "join_date",
  "lastDailyTicketCase",
  "last_steam_inv_update",
];
const trxKeys = ["status"];
const profitKeys = ["winnings"];
const imgKeys = ["avatar"];
const coinsKeys = ["balance", "coins", "balanceAmount"];
const longStrings = ['token']

const formatEntry = (
  key: string,
  // eslint-disable-next-line
  value: any,
  t: TFunction<"translation", undefined>,
): JSX.Element => {
  if (dateKeys.includes(key)) {
    const parsedDate =
      value > 0 ? moment.unix(value).format("YYYY/MM/DD h:mm:ss") : "No date";
    return <React.Fragment>{parsedDate}</React.Fragment>;
  }
  if (typeof value === "object") {
    if (key === "items") {
      const dispatch = useDispatch();

      const arr: GameSkin[] = value.map((val: GameSkin) => val);
      // f*cking javascript. won't work without creating a new variable
      const button = (
        <Button
          onClick={() =>
            dispatch(
              openModal({
                modalHeader: t("viewItems"),
                modalContent: <SkinModal />,
                modalFooter: <CloseFooter />,
                data: arr,
              }),
            )
          }
        >
          {t("viewItems")}
        </Button>
      );

      value = button;
    } else if (key === "crypto_address") {
      const dispatch = useDispatch();
      const cryptoContent = (
        <div className="d-flex gap-2 flex-column">
          {Object.entries(value).map(([key, val]) => {
            return (
              <div key={key}>
                <CopyField
                  value={
                    <span className="fw-bold">
                      <span className="text-golden d-block overflow-ellipsis crypto-address">
                        {key} : {JSON.stringify(val)}
                      </span>
                    </span>
                  }
                  copyValue={String(val)}
                />
              </div>
            );
          })}
        </div>
      );

      const button = (
        <Button
          onClick={() =>
            dispatch(
              openModal({
                modalHeader: t("cryptoAddresses"),
                modalContent: cryptoContent,
                modalFooter: <CloseFooter />,
              }),
            )
          }
        >
          {t("view")}
        </Button>
      );

      value = button;
    } else {
      value = JSON.stringify(value);
    }
  }
  if (profitKeys.includes(key)) {
    value = (
      <span className={value > 0 ? "text-win" : "text-red"}>{value}</span>
    );
  }

  if (coinsKeys.includes(key)) {
    value = formatBalanceNum(value);
  }
  if (imgKeys.includes(key)) {
    value = <img src={value} className="icon-image-lg rounded-full" />;
  }
  if (typeof value === "boolean") {
    value = String(value === true ? "true" : "false");
  }
  if(longStrings.includes(key)){
    value = <span className="overflow-hidden d-block max-w-sm">{value}</span>
  }
  if (trxKeys.includes(key)) {
    value = t(`tableData.${value}`);
  } else if (typeof value === "number") {
    value = value.toFixed(0);
  }
  return value;
};

interface TransformedData {
  [key: string]: JSX.Element | string | number | boolean | null | undefined;
}

export const transformData = (
  // eslint-disable-next-line
  data: any[],
  t: TFunction<"translation", undefined>,
): TransformedData[] => {
  return data?.map((transaction) =>
    Object.entries(transaction).reduce((acc, [key, value]) => {
      acc[key] = formatEntry(key, value, t);
      return acc;
    }, {} as TransformedData),
  );
};

export const playGameSound = (isWin: boolean) => {
  try {
    const audioFileName = isWin ? "win.mp3" : "lose.mp3";
    const audioElement = new Audio(`/audio/${audioFileName}`);
    audioElement.play().catch((error) => {
      console.error("Error playing audio:", error);
    });
  } catch (e) {
    console.error("Error initializing audio:", e);
  }
};

export const isSerializable = (value: unknown): boolean => {
  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    value === null ||
    value === undefined
  ) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.every(isSerializable);
  }

  if (typeof value === "object" && value !== null) {
    return Object.values(value).every(isSerializable);
  }

  return false;
};

export const getCryptoIcon = (coin: string) : string => {
  coin = coin.split(".")[0];
  const baseUrl =
    "https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/128/color";
  const symbol = coin.toLowerCase().replace(".", "");
  return `${baseUrl}/${symbol}.png`;
};

export const checkIsChanged = (
  tickets: number[],
  userAnswers: number[],
  level: number,
): boolean => {
  return userAnswers.some(
    (answer, index) => tickets[index] !== answer && level > index,
  );
};
export function getRandomExceptSelected(
  selectedNumber: number,
  useThreeNumbers: boolean,
) {
  const possibleNumbers = useThreeNumbers ? [1, 2, 3] : [1, 2];

  // Filter out the selected number
  const remainingNumbers = possibleNumbers.filter(
    (num) => num !== selectedNumber,
  );

  // Randomly select one of the remaining numbers
  const randomIndex = Math.floor(Math.random() * remainingNumbers.length);
  const numberToPush = remainingNumbers[randomIndex];

  return numberToPush;
}

export const formatBalanceNum = (inputnum: number): string => {
  return new Intl.NumberFormat().format(inputnum);
};
