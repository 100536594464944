import React, { useEffect, useState } from "react";
import apiRequest from "../../../util/apiUtil";
import axiosInstance from "../../../util/axios";
import QRCode from "react-qr-code";
import "./index.css";
import CopyField from "../../UI/CopyField";
import { getCryptoIcon } from "../../../util/helpers";
import BackButton from "../../UI/BackButton";
import CustomInput from "../../Inputs";
import { Trans, useTranslation } from "react-i18next";
import { Coin, CryptoTrx, CryptoWithdraw } from "../../../types";
import { COINS, COINS_PER_DOLLAR } from "../../../util/const";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import { toast } from "react-toastify";
import Balance from "../../UI/Balance";
import BigNumber from "bignumber.js";

const convertCryptoToDollars = (cryptoAmount: string, price: number) => {
  return (parseFloat(cryptoAmount) / COINS_PER_DOLLAR) * price;
};

const convertDollarsToCrypto = (
  dollarAmount: string,
  price: number,
): string => {
  const dollarAmountBN = new BigNumber(dollarAmount);
  const priceBN = new BigNumber(price);
  const result = dollarAmountBN.div(priceBN.div(COINS_PER_DOLLAR));
  return String(result.toFixed());
};

interface CryptoModalProps {
  chosenCoin: Coin;
  goBack: () => void;
  action: "deposit" | "withdraw";
}

const CryptoModal: React.FC<CryptoModalProps> = ({
  chosenCoin,
  goBack,
  action,
}) => {
  const { crypto_address, balance } = useSelector(selectUser);
  const [fetching, setFetching] = useState<boolean>(false);
  const [selectedCoin, setSelectedCoin] = useState<Coin>(chosenCoin);
  const [data, setData] = useState<CryptoTrx | null>(null);

  const [lastTouched, setLastTouched] = useState<string>("");
  const [withdraw, setWithdraw] = useState<CryptoWithdraw>({
    amount: "",
    address: action === "deposit" ? crypto_address[chosenCoin] || "" : "",
    currency: chosenCoin,
  });
  // eslint-disable-next-line
  const [dollarAmount, setDollarAmount] = useState<string>("");
  const [coins, setCoins] = useState<string>("");

  const handleSelect = async (coin: Coin) => {
    setSelectedCoin(coin);
    setWithdraw({
      ...withdraw,
      currency: coin,
      address: action === "deposit" ? crypto_address[coin] || "" : "",
    });
    setFetching(true);
    try {
      const data = await apiRequest({
        axiosFunction: () => axiosInstance.post(`/getCryptoAddress/${coin}`),
      });
      setData(data);
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };

  const handleWithdraw = async () => {
    if (balance < Number(coins)) {
      toast.error(t("errors.notEnoughBalance"));
      return;
    }
    setFetching(true);
    try {
      if (action === "withdraw") {
        const data = await apiRequest({
          axiosFunction: () =>
            axiosInstance.post(`/crypto/withdraw`, {
              ...withdraw,
              amount: Number(withdraw.amount),
            }),
        });
        setData(data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (chosenCoin) {
      handleSelect(chosenCoin);
    }
  }, [chosenCoin, action]);

  const { t } = useTranslation();

  useEffect(() => {
    if (dollarAmount && data && lastTouched === "usd") {
      const cryptoValue = convertDollarsToCrypto(dollarAmount, data.price);

      setWithdraw({ ...withdraw, amount: cryptoValue });
      setCoins(String(Number(dollarAmount) * COINS_PER_DOLLAR));
    }
  }, [dollarAmount, lastTouched]);

  useEffect(() => {
    if (withdraw?.amount && lastTouched === "crypto") {
      const dollarValue = convertCryptoToDollars(
        String(withdraw?.amount),
        Number(data?.price),
      );
      setDollarAmount(String(dollarValue));
      setCoins(String(dollarValue * COINS_PER_DOLLAR));
    }
  }, [withdraw?.amount, lastTouched]);

  useEffect(() => {
    if (coins && lastTouched === "coins") {
      const dollarAmount = String(Number(coins) / COINS_PER_DOLLAR);
      const cryptoValue = convertDollarsToCrypto(
        dollarAmount,
        Number(data?.price),
      );

      setWithdraw({ ...withdraw, amount: cryptoValue });
      setDollarAmount(dollarAmount);
    }
  }, [coins, lastTouched]);

  useEffect(() => {
    const cryptoValue = convertDollarsToCrypto(
      dollarAmount,
      Number(data?.price),
    );

    setWithdraw({ ...withdraw, amount: cryptoValue });
  }, [data?.price]);
  const number = data
    ? Math.floor((data.price / COINS_PER_DOLLAR) * 100) / 100
    : 0;
  const formattedNumber = number.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const parts = formattedNumber.split(".");
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const decimalPart = parts[1] || "00";

  const finalFormattedNumber = `${integerPart}.${decimalPart}`;
  const withdrawIsTooLow = parseInt(dollarAmount) < 5;
  return (
    <div className="d-flex flex-column gap-2">
      <div>
        <BackButton goBack={goBack} />
      </div>
      <div className="d-flex gap-2 overflow-auto py-3 justify-content-between">
        {COINS.map((coin) => (
          <div
            key={coin}
            className={`coin-selection transition-all d-flex flex-1 rounded-md border p-3 gap-1 border-primary ${
              selectedCoin === coin ? "bg-win" : "bg-secondary"
            } flex-column text-center justify-center`}
            onClick={() => handleSelect(coin)}
          >
            <img
              src={getCryptoIcon(coin.split(".")[0])}
              alt={`${coin} icon`}
              className="coin-icon"
            />
            <span className="fw-bold">{coin}</span>
          </div>
        ))}
      </div>
      {action === "deposit" && data && (
        <div className="qr-block py-1">
          <hr className="mb-3" />
          <div className="d-flex flex-column gap-2 border-secondary border rounded-md py-5">
            <div className="d-flex py-3 flex-wrap align-items-center justify-content-center gap-5">
              <QRCode value={`crypto:${data.address}?amount=${data.price}`} />
              <div className="d-flex flex-column gap-3 px-3">
                <p className="fw-bold text-center text-green">
                  {" "}
                  {t("cryptoDepositNotice")}
                </p>
                <CopyField
                  value={
                    <span className="fw-bold">
                      <span className="text-golden d-block overflow-ellipsis crypto-address">
                        {data.address}
                      </span>
                    </span>
                  }
                  copyValue={data.address}
                />
                <CopyField
                  value={
                    <span className="fw-bold">
                      <span className="text-golden">
                        {finalFormattedNumber}$
                      </span>
                      {/* <img
                        className="mb-1"
                        src="./images/balance.svg"
                        alt="balance"
                      /> */}
                      / {selectedCoin}
                    </span>
                  }
                  copyValue={data.price.toString()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {action === "withdraw" && (
        <div className="withdraw-block py-1">
          <hr className="mb-3" />
          <div className="d-flex flex-column gap-2 border-secondary border px-3 rounded-md py-4">
            <div className="flex justify-end">
              <Balance balance={balance} />
            </div>
            <div className="d-flex flex-column gap-4">
              <h5 className="text-golden text-center fw-bold font-bold">
                {
                  <Trans
                    i18nKey={"withdrawalText"}
                    values={{ crypto: selectedCoin }}
                  />
                }
                {withdrawIsTooLow && (
                  <p className="text-red">{t("minWithdraw")}</p>
                )}
              </h5>
              <div className="d-flex flex-column justify-content-center gap-3">
                <div className="d-flex align-items-center justify-content-center flex-md-row flex-column gap-2">
                  <CustomInput
                    type="number"
                    name="coinsamount"
                    label={t("coinsamount")}
                    value={coins}
                    step={"any"}
                    onChange={(e) => {
                      setLastTouched("coins");
                      setCoins(e);
                    }}
                    rootClass="crypto-input"
                  />

                  <div className="crypto-separator mt-4 fw-bold text-lg text-golden d-none d-md-block">
                    {" "}
                    -{" "}
                  </div>
                  <CustomInput
                    type="number"
                    name="usdamount"
                    label={t("usdamount")}
                    value={dollarAmount}
                    step={"any"}
                    onChange={(e) => {
                      setLastTouched("usd");
                      setDollarAmount(e);
                    }}
                    rootClass="crypto-input"
                  />
                  <div className="crypto-separator mt-4 fw-bold text-lg text-golden d-none d-md-block">
                    {" "}
                    -{" "}
                  </div>
                  <CustomInput
                    type="number"
                    name="amount"
                    label={selectedCoin}
                    value={withdraw?.amount}
                    step={"any"}
                    onChange={(e) => {
                      setLastTouched("crypto");
                      setWithdraw({
                        ...withdraw,
                        amount: e,
                      });
                    }}
                    rootClass="crypto-input"
                  />
                </div>

                <CustomInput
                  type="text"
                  label={t("address")}
                  name="address"
                  value={withdraw?.address}
                  onChange={(e) =>
                    setWithdraw({
                      ...withdraw,
                      address: String(e),
                    })
                  }
                  rootClass="crypto-address mx-auto"
                />
              </div>
              <Button
                disabled={fetching || withdrawIsTooLow}
                onClick={handleWithdraw}
                className="py-2 mx-auto d-block px-5"
              >
                {t("withdraw")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CryptoModal;
